import styles from "./display-track.module.css";

const DisplayTrack = ({ currentTrack, audioRef, setDuration, progressBarRef, onEnded, tracks, trackIndex, setIsFirstImageLoaded }) => {
    const onLoadedMetadata = () => {
        const seconds = audioRef.current.duration;
        setDuration(seconds);
        progressBarRef.current.max = seconds;
    };

    const onImageLoadHandler = (e) => {
        e.preventDefault();
        if (trackIndex === 0) {
            setIsFirstImageLoaded(true);
        }
    }
    
    return(
        <div className = "container-fluid">
            <div className = "row justify-content-center">
                <div className = "col-12">
                    <img className = { styles.img } src = { tracks[trackIndex].thumbnail} onLoad = { (e) => onImageLoadHandler(e) } alt = { tracks[trackIndex].title }></img>
                    <div className = { ["fs-5", "mt-2", styles.title].join(" ") }>{ tracks[trackIndex].title }</div>
                    <audio src = { currentTrack.src } ref = { audioRef } onLoadedMetadata = { onLoadedMetadata } onEnded = { onEnded }/>
                </div>
            </div>    
        </div>
    )
}

export default DisplayTrack