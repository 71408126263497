import { useRef, useState } from "react";
import DisplayTrack from "./display-track";
import ProgressBar from "./progress-bar";
import Controls from "./controls";
import tracks from "../data/tracks";

const AudioPlayer = ({ setIsFirstImageLoaded }) => {
  const [trackIndex, setTrackIndex] = useState(0);
  const [currentTrack, setCurrentTrack] = useState(tracks[trackIndex]);
  const [timeProgress, setTimeProgress] = useState(0);
  const [duration, setDuration] = useState(0);  
  const audioRef = useRef();
  const progressBarRef = useRef();
  const [isPlaying, setIsPlaying] = useState(false);

  const togglePlayPause = () => {
    setIsPlaying((prev) => !prev);
  };

  const onEnded = () => {
    audioRef.current.currentTime = 0;
    progressBarRef.current.value = 0;
    togglePlayPause();
  };

  const handlePrevious = () => {
    if (trackIndex === 0) {
        let lastTrackIndex = tracks.length - 1;
        setTrackIndex(lastTrackIndex);
        setCurrentTrack(tracks[lastTrackIndex]);
    } else {
        setTrackIndex((prev) => prev - 1);
        setCurrentTrack(tracks[trackIndex - 1]);
    }
    togglePlayPause();    
  };

  const handleNext = () => {
    if (trackIndex >= tracks.length - 1) {
        setTrackIndex(0);
        setCurrentTrack(tracks[0]);
    } else {
        setTrackIndex((prev) => prev + 1);
        setCurrentTrack(tracks[trackIndex + 1]);
    }
    togglePlayPause();
  };

  return (
      <div className = "container-fluid">
        <div className = "row justify-content-center mt-3 mb-3">
          <div className = "col-md-5 text-center">
              <DisplayTrack currentTrack = { currentTrack } audioRef = { audioRef } setDuration = { setDuration } progressBarRef = { progressBarRef } onEnded = { onEnded } tracks = { tracks } trackIndex = { trackIndex } setIsFirstImageLoaded = { setIsFirstImageLoaded }></DisplayTrack>
              <ProgressBar progressBarRef = { progressBarRef } audioRef = { audioRef } timeProgress = { timeProgress } duration = { duration }></ProgressBar>
              <Controls audioRef = { audioRef } progressBarRef = { progressBarRef } duration = { duration } setTimeProgress = { setTimeProgress } isPlaying = { isPlaying } togglePlayPause = { togglePlayPause } handlePrevious = { handlePrevious } handleNext = { handleNext }></Controls>
          </div>
        </div>
      </div>
  );
}

export default AudioPlayer