import { Fragment, useState } from "react";
import { Alert, Button, Modal } from "react-bootstrap";
import services from "../data/services";

const ContactModal = ({ show, handlers, strings }) => {
    const [email, setEmail] = useState("");
    const [statusMessage, setStatusMessage] = useState("");
    const [isEmailProcessed, setIsEmailProcessed] = useState(false);

    const validateEmail = () => {
        /**
         * The `validateEmail` method accepts an email string and validates it.
         * 
         * @param {string} str
         * @return {boolean}
         * @raise none
         */
        // Regular expression for email validation that supports Unicode characters
        const REGEX_EMAIL = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
    
        try {
          if (REGEX_EMAIL.test(email)) {
            return true
          } else {
            return false
          }
        } catch {
          return false
        }
    }  

    const handleSubmit = (e) => {
        e.preventDefault();
        let _isGoodEmail = validateEmail();
        if (!_isGoodEmail) {
            setStatusMessage(strings.contactModal.badEmail);
        } else {
            setStatusMessage("");
            let _command = '{"action": "save-email", "email": "' + email + '"}'
            handlers.request(services.webServices, _command).then(_response => {
                if (null !== _response) {
                    let _data = JSON.parse(_response);
                    if ("true" === _data["isSaved"]){
                        setIsEmailProcessed(true);
                    } else {
                        setStatusMessage(strings.contactModal.badSend);
                    }
                }
            })
        }
    }

    const handleCloseAfterSend = (e) => {
        e.preventDefault();
        handlers.handleClose();
        setIsEmailProcessed(false);
    }

    return (
        <Modal show = { show } onHide = { handlers.handleClose }>
            <Modal.Header closeButton>
                <Modal.Title>{ strings.contactModal.title }</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {(!isEmailProcessed) &&
                    <Fragment>
                        {statusMessage && <Alert variant = "secondary">{ statusMessage }</Alert>}
                        <form className = "form-floating">
                            <input type = "email" className = "form-control" id = "emailForm" placeholder = "name@example.com" maxlength = "254" onChange = { (e) => setEmail(e.target.value) }></input>
                            <label htmlFor = "emailForm" className = "form-label">{ strings.contactModal.enterEmail }</label>
                        </form>
                    </Fragment>
                }
                {(isEmailProcessed) &&
                    <div>{ strings.contactModal.goodSend }</div>
                }
            </Modal.Body>
            <Modal.Footer>
                {(!isEmailProcessed) &&
                    <Button variant = "secondary" onClick = { (e) => handleSubmit(e) }>
                        { strings.contactModal.submitButton }
                    </Button>
                }
                {(isEmailProcessed) &&
                    <Button variant = "secondary" onClick = { (e) => handleCloseAfterSend(e) }>
                        { strings.contactModal.closeButton }
                    </Button>
                }
            </Modal.Footer>
        </Modal>         
    )   
}

export default ContactModal