import { Fragment, useState } from "react";
import ContactModal from "./contact-modal";
import styles from "./logo-area.module.css";

const LogoArea = ({ ids, handlers, strings }) => {
    const [show, setShow] = useState(false);

    const contactClickHandler = (e) => {
        e.preventDefault();
        setShow(true);
    } 
    
    const handleClose = () => {
        setShow(false);
    }

    return(
        <Fragment>
            <div id = { ids.area.id } className = { [styles.logoAreaContainer, "container-fluid"].join(" ")}>
                <div className = "row align-items-center p-1">
                    <div id = { ids.branding.id } className = "col">
                        <div className = "row justify-content-start align-items-center">
                            <div className = { [styles.brandingContainer, "col"].join(" ") } onClick = { (e) => contactClickHandler(e) }>
                                <div className = { styles.branding }>Crystal</div>
                                <div className = { styles.branding }>Tick</div>
                            </div>
                        </div>
                    </div>
                    <div id = { ids.lang.id } className = "col">
                        <div className = "row justify-content-end align-items-center">
                            <div className = "col-auto pe-0">
                                <svg className = { [styles.contactIcon, "d-inline-block"].join(" ") } onClick = { (e) => contactClickHandler(e) } xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" enableBackground="new 0 0 64 64"><path d="m32 2c-16.568 0-30 13.432-30 30 0 16.568 13.432 30 30 30s30-13.432 30-30c0-16.568-13.432-30-30-30m0 48c-1.371-1.814-20.53-12.883-16.602-25.218 3.53-11.073 15.094-6.597 16.602-.594 1.094-5.635 12.949-10.694 16.604.584 3.925 12.136-15.237 23.785-16.604 25.228"/></svg>
                            </div>
                            <div className = "col-auto ps-0">
                                <select className = "form-select" onChange = { (e) => handlers.switchLanguage(e) }>
                                    <option defaultValue value = "EN">EN</option>
                                    <option value = "ES">ES</option>
                                    <option value = "BR">BR</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ContactModal show = { show } handlers = {{ request: handlers.request, handleClose: handleClose }} strings = { strings }></ContactModal>
        </Fragment>
    );   
}

export default LogoArea