import { useCallback, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./controls.module.css";

const Controls = ({ audioRef, progressBarRef, duration, setTimeProgress, isPlaying, togglePlayPause, handlePrevious, handleNext }) => {
    const playAnimationRef = useRef();

    const skipForward = () => {
        audioRef.current.currentTime += 15;
    };

    const skipBackward = () => {
        audioRef.current.currentTime -= 15;
    };

    const repeat = useCallback(() => {
        let _progressBarWidth = parseInt(window.getComputedStyle(progressBarRef.current).getPropertyValue("width"));
        let _thumbWidth = 16 * 0.75; // Font size * thumb width expressed in rem
        let _thumbPctOffset = Math.ceil(((_thumbWidth / _progressBarWidth) * 100) / 2);
        
        const currentTime = parseInt(audioRef.current.currentTime);
        setTimeProgress(currentTime);
        progressBarRef.current.value = currentTime;
        progressBarRef.current.style.setProperty(
            "--range-progress",
            `${Math.ceil((currentTime / duration) * 100)}%`
        );

        playAnimationRef.current = requestAnimationFrame(repeat);
    }, []);

    useEffect(() => {
        if (isPlaying) {
          audioRef.current.play();
        } else {
          audioRef.current.pause();
        }
        playAnimationRef.current = requestAnimationFrame(repeat);
    }, [isPlaying, audioRef, repeat]);    

    return(
        <div className = "container-fluid">
            <div className = "row justify-content-center">
                <div className = { [styles.audioControls, "col-md-12"].join(" ") }>
                    <button className = "btn btn-outline-light me-3" onClick={ handlePrevious }>
                        <FontAwesomeIcon icon = "fa-solid fa-chevron-left"></FontAwesomeIcon>
                    </button>
                    <button className = "btn btn-outline-light me-3" onClick = { skipBackward }>
                        <FontAwesomeIcon icon = "fa-solid fa-backward"></FontAwesomeIcon>
                    </button>
                    <button className = "btn btn-outline-light" onClick = { togglePlayPause }>
                        {isPlaying ? <FontAwesomeIcon icon = "fa-solid fa-pause"></FontAwesomeIcon> : <FontAwesomeIcon icon = "fa-solid fa-play"></FontAwesomeIcon>}
                    </button>
                    <button className = "btn btn-outline-light ms-3" onClick = { skipForward }>
                        <FontAwesomeIcon icon = "fa-solid fa-forward"></FontAwesomeIcon>
                    </button>
                    <button className = "btn btn-outline-light ms-3" onClick = { handleNext }>
                        <FontAwesomeIcon icon = "fa-solid fa-chevron-right"></FontAwesomeIcon>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Controls