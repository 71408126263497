import { Fragment, useEffect, useState } from "react";
import LogoArea from "./logo-area";
import Teaser from "./teaser";
import AudioPlayer from "./audio-player";
import Footer from "./footer";
import enStrings from "../strings/en-us/strings";
import esStrings from "../strings/es/strings";
import brStrings from "../strings/br/strings";

const App = () => {
  const DOMElements = {
    "root": { "id": "root" },
    "LogoArea": {
      "area": { "id": "logoArea" },
      "branding": { "id": "logoAreaBranding" },
      "teaser": { "id": "logoAreaTeaser" },
      "lang" : { "id": "logoAreaLang" }
    }
  }

  const [strings, setStrings] = useState(enStrings);
  const [isFirstImageLoaded, setIsFirstImageLoaded] = useState(false);
    
  const switchLanguage = (e) => {
      // let selectedLanguage = e.target.innerHTML.toLowerCase();
      let selectedLanguage = e.target.value.toLowerCase();
      switch (selectedLanguage){
        case ("en"):
          setStrings(enStrings);
          break;
        case ("es"):
          setStrings(esStrings);
          break;
        case ("br"):
          setStrings(brStrings);
          break;
        default:
          setStrings(enStrings);
          break;
      }
  }

  const request = async (url, data) => {
    let response = await fetch(url, {
          method: "POST",
          headers: {"Content-Type": "application/x-www-form-urlencoded"},
          body: "data="+data
        }
    );
    if (response.ok) {
        let text = await response.text();
        return text;
    } else {
      return null;
    }
  }  

  useEffect(() => {
    if (isFirstImageLoaded) {
      const root = document.getElementById("root");
      let opacity = 0;
      let intervalID = setInterval(() => {
          if (opacity < 1) {
              opacity = opacity + 0.005
              root.style.opacity = opacity;
          } else {
              clearInterval(intervalID);
          }
      }, 15);
    }
  }, [isFirstImageLoaded])

  return (
    <Fragment>
      <LogoArea ids = { DOMElements.LogoArea } handlers = {{ switchLanguage: switchLanguage, request: request }} strings = { strings }></LogoArea>
      <Teaser strings = { strings }></Teaser>
      <AudioPlayer setIsFirstImageLoaded = { setIsFirstImageLoaded }></AudioPlayer>
      <Footer></Footer>
    </Fragment>
  )
}

export default App;
