const brStrings = {
    releaseTeaser: {
        releaseHeader: "baixar hi fi disponível agora"
    },
    contactModal: {
        title: "Quer ouvir mais músicas de Crystal Tick?",
        body: "Envie-nos uma mensagem em crystaltick@pm.me. Os fãs recebem conteúdo exclusivo.",
        enterEmail: "Insira o seu endereço de email",
        submitButton: "Enviar",
        closeButton: "Fechar",
        badEmail: "Seu endereço de e-mail é uma merda. Insira um endereço de e-mail real.",
        goodSend: "Obrigado! Verifique seu e-mail para notícias sobre novas faixas do Crystal Tick exclusivamente para fãs.",
        badSend: "Algo deu errado. Por favor, tente novamente."
    }    
}

export default brStrings