import styles from "./footer.module.css";

const Footer = () => {
    return (
        <div className = { [styles.footerContainer, "container-fluid"].join(" ") }>
            <div className = "row">
                <div className = "col-12 text-end">
                    <span className = { styles.copyright }>Copyright © 2023</span>
                </div>
            </div>
        </div>
    )
}

export default Footer