import agujero from "./agujero_master.mp3";
import montevideo from "./montevideo_master.mp3";
import titane from "./titane_master.mp3";
import titaneCover from "./titane_cover.jpg";
import agujeroCover from "./agujero_cover.jpg";
import montevideoCover from "./montevideo_cover.jpg";

const tracks = [
  {
    title: "Titane",
    src: titane,
    author: "Titane",
    thumbnail: titaneCover,
  },  
  {
    title: "Agujero",
    src: agujero,
    author: "Crystal Tick",
    thumbnail: agujeroCover,
  },
  {
    title: "Montevideo",
    src: montevideo,
    author: "Montevideo",
    thumbnail: montevideoCover,
  }
];

export default tracks