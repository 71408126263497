import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './components/app';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import "./index.css";

// Add Font Awesome icons to library for implicit use across components
library.add(fas);

// Register the service worker
if("serviceWorker" in navigator){
  navigator.serviceWorker.register('sw.js');
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);