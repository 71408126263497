const enStrings = {
    releaseTeaser: {
        releaseHeader: "hi-fi download available now"
    },
    contactModal: {
        title: "Want to hear more music from Crystal Tick?",
        body: "Send us a message at crystaltick@pm.me. Fans get exclusive content.",
        enterEmail: "Enter your email address",
        submitButton: "Submit",
        closeButton: "Close",
        badEmail: "Your email address sucks. Please enter a real email address.",
        goodSend: "Thanks! Check your email for news on new Crystal Tick tracks exlusively for fans.",
        badSend: "Something went wrong. Please try again."
    }
}

export default enStrings