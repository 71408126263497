import styles from "./progress-bar.module.css";

const ProgressBar = ({ progressBarRef, audioRef, timeProgress, duration }) => {
    const handleProgressChange = () => {
        audioRef.current.currentTime = progressBarRef.current.value;
    };

    const formatTime = (time) => {
        if (time && !isNaN(time)) {
          const minutes = Math.floor(time / 60);
          const formatMinutes =
            minutes < 10 ? `0${minutes}` : `${minutes}`;
          const seconds = Math.floor(time % 60);
          const formatSeconds =
            seconds < 10 ? `0${seconds}` : `${seconds}`;
          return `${formatMinutes}:${formatSeconds}`;
        }
        return '00:00';
    };    

    return (
        <div className = "container-fluid">
            <div className = "row justify-content-center mt-2 mb-3">
                <div className = "col-md-12">
                    <div className = { ["d-inline-block", "me-3", styles.time, styles.current].join(" ") }>{ formatTime(timeProgress) }</div>
                    <input type = "range" ref = { progressBarRef } defaultValue = "0" onChange = { handleProgressChange } />
                    <div className = { ["d-inline-block", "ms-3", styles.time].join(" ") }>{ formatTime(duration) }</div>
                </div>
            </div>
        </div>
    );
}

export default ProgressBar