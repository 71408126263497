const esStrings = {
    releaseTeaser: {
        releaseHeader: "descarga de hi-fi disponible ahora"
    },
    contactModal: {
        title: "¿Quiere escuchar más de Crystal Tick?",
        body: "Enviénos un mensaje en crystaltick@pm.me. Fans obtienen música exclusiva.",
        enterEmail: "Ingrese su dirección de correo electrónico",
        submitButton: "Entregar",
        closeButton: "Cerrar",
        badEmail: "Por favor, introduzca una dirección de correo electrónico real.",
        goodSend: "¡Gracias! Revisa tu correo electrónico para obtener noticias sobre las nuevas pistas de Crystal Tick exclusivas para fans.",
        badSend: "Algo salió mal. Inténtelo de nuevo."
    }    
}

export default esStrings